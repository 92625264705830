@font-face {
  font-family: 'Lato';
  src: url(./assets/fonts/Lato-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Black';
  src: url(./assets/fonts/Lato-Black.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}