.Requests-details {
    width: 100%;
    border-collapse: collapse;
}
.Requests-details th {
    width: 315px;
    font-size: 2em;
    padding: 5px;
}

.Requests-details td {
    font-size: 1.2em;
    padding: 5px;
}

.Requests-details .Result {
    padding: 20px 5px;
    text-transform: uppercase;
}

.Requests-details .Files {
    border-bottom: 2px solid black;
}

.Requests-details .Company-data {
    border-top: 2px solid black;
}

.Requests-details .Files td {
    padding-bottom: 20px;
}

.Requests-details .Company-data td {
    padding-top: 20px;
}
.customers-company-links-tooltip .tooltip-inner {
  background-color: #fafafa;
  color: black;
  border: 1px solid black;
  max-width: 100%;
}
.customers-company-links-tooltip .tooltip-inner div {
  padding: 15px 15px 0 5px;
}
.tooltip.in.customers-company-links-tooltip {
  opacity: 1;
}
.close-x {
  color: #777;
  font: 14px/100% arial, sans-serif;
  position: absolute;
  right: 5px;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  top: 5px;
  right: 10px;
}